import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column align-center text-center mt-4"},[_c('div',{staticClass:"my-4 text-h5"},[_c('span',[_vm._v(_vm._s(_vm.$t('Create cart for...')))])]),_c('search-customer',{attrs:{"options":{ showChip: false, grid: { headers: _vm.headers } }},scopedSlots:_vm._u([{key:"item.firstname",fn:function(ref){
var item = ref.item;
return [_c(_vm.canRead || _vm.canUpdate || _vm.canDelete ? 'router-link' : 'span',{tag:"router-link",attrs:{"to":{ name: 'customer-update', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.firstname)+" ")])]}},{key:"item.lastname",fn:function(ref){
var item = ref.item;
return [_c(_vm.canRead || _vm.canUpdate || _vm.canDelete ? 'router-link' : 'span',{tag:"router-link",attrs:{"to":{ name: 'customer-update', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.lastname)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(Boolean(_vm.requiredDob) && !Boolean(item.dob))?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("error")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Missing required date of birth')))])]):_c(VBtn,{attrs:{"fab":"","depressed":"","x-small":"","color":"primary","loading":item === _vm.customer && _vm.loading,"disabled":_vm.loading},on:{"click":function($event){return _vm.onCustomerSelect(item)}}},[_c('rtl-chevron-icon')],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }