import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('grid-layout',_vm._b({attrs:{"selected-items":_vm.selected},on:{"update:selectedItems":function($event){_vm.selected=$event},"update:selected-items":function($event){_vm.selected=$event},"paramsChanged":_vm.reloadCollection},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.canDeleteCart)?_c(VBtn,{attrs:{"depressed":"","color":"primary","to":{ name: 'cart-create' }}},[_vm._v(" "+_vm._s(_vm.$t('Create cart'))+" ")]):_vm._e()]},proxy:true},{key:"item.number",fn:function(ref){
var item = ref.item;
return [_c(_vm.canAccessCart(item).value ? 'router-link' : 'span',{tag:"router-link",attrs:{"to":{ name: 'cart-update', params: { id: item.id } }}},[_vm._v(" "+_vm._s(_vm.getShortId(item))+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c((_vm.canUpdateCustomer || _vm.canDeleteCustomer) && item.customer.customer_id ? 'router-link' : 'span',{tag:"router-link",attrs:{"to":{
        name: 'customer-update',
        params: { id: item.customer.customer_id }
      }}},[_vm._v(" "+_vm._s(_vm.getCustomerName(item).value)+" ")])]}},{key:"item.summary.total",fn:function(ref){
      var item = ref.item;
return [_c('money-preview',{attrs:{"value":item.summary.total_due}})]}},{key:"item.edit",fn:function(ref){
      var item = ref.item;
return [(_vm.canAccessCart(item).value)?_c('button-action',{attrs:{"icon-name":"edit","icon":"","label":_vm.$t('Edit'),"disabled":_vm.grid.loading,"to":{ name: 'cart-update', params: { id: item.id } }}}):_vm._e()]}},{key:"item.delete",fn:function(ref){
      var item = ref.item;
return [_c('cart-action-delete',{attrs:{"value":item,"disabled":_vm.grid.loading,"icon":""},on:{"onSuccess":_vm.onDeleteSuccess}})]}},{key:"grid.footer.actions",fn:function(){return [_c('div',{staticClass:"d-flex flex-gap-8"},[(_vm.canDeleteCart)?_c('cart-action-mass-delete',{attrs:{"items":_vm.selected,"disabled":_vm.grid.loading || !_vm.selected.length},on:{"onSuccess":_vm.onMassDeleteSuccess,"onError":function($event){_vm.errors = $event}}}):_vm._e(),_c('button-action',{attrs:{"label":_vm.$t('Force delete'),"items":_vm.selected,"text":"","disabled":_vm.grid.loading || !_vm.selected.length},on:{"click":function($event){_vm.errors = { message: _vm.$t('Confirm force delete') }}}})],1)]},proxy:true}])},'grid-layout',{ grid: _vm.grid, params: _vm.params, options: _vm.options, title: _vm.$t('Carts'), gridProps: { showSelect: _vm.canDeleteCart } },false))}
var staticRenderFns = []

export { render, staticRenderFns }